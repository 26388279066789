import React from 'react';
import PropTypes from 'prop-types';
import '../styles/SavedForLaterItem.css'; // Import the CSS file for styling

import item1 from '../assets/product-images/hat.png';
import item2 from '../assets/product-images/shirt.png';
import item3 from '../assets/product-images/long-sleeve.png';

// Function to dynamically select the correct image based on item's id
const selectItemImage = (itemId) => {
  switch (itemId) {
      case 1:
          return item1;
      case 2:
          return item2;
      case 3:
          return item3;
      default:
          return ''; // Return empty string if no matching image found
  }
};

const SavedForLaterItem = ({ item, onAddBackToCart, onRemoveFromSaved }) => {
    const handleMoveToCart = () => {
        onAddBackToCart(item);
        onRemoveFromSaved(item.id);
    };
    const handleRemoveFromSaved = () => {
        onRemoveFromSaved(item.id);
    };

    return (
        <li className="saved-for-later-item" style={{ backgroundColor: '#cd5b12' }}>
            <div className="item-details">
            <div className="item-image">
                    <img src={selectItemImage(item.id)} alt={item.name} />
                </div>
                <span className="item-name">{item.name}</span>
                <span className="item-price">${item.price}</span>
            </div>
            <div className="item-actions">
                <button style={{ backgroundColor: '#0d3149' }} className="move-to-cart-button" onClick={handleMoveToCart}>
                    Move to Cart
                </button>
                <button style={{ backgroundColor: '#0d3149' }} className="remove-from-saved-button" onClick={handleRemoveFromSaved}>
                    Remove
                </button>
            </div>
        </li>
    );
};

SavedForLaterItem.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
    }).isRequired,
    onAddBackToCart: PropTypes.func.isRequired,
    onRemoveFromSaved: PropTypes.func.isRequired,
};

export default SavedForLaterItem;
