// CheckoutModal.js
import React from 'react';
import '../styles/CheckoutModal.css'; // Import the CSS file for styling

const CheckoutModal = ({ cartItems }) => {
  // Calculate total price
  const totalPrice = cartItems.reduce((total, item) => total + item.price, 0);

  return (
    <div className="checkout-modal">
      <h2>Checkout</h2>
      <ul>
        {cartItems.map((item) => (
          <li key={item.id}>
            <span className="item-name">{item.name}</span>
            <span className="item-price">${item.price}</span>
          </li>
        ))}
      </ul>
      <div className="total-price">Total: ${totalPrice}</div>
      <button className="confirm-purchase-button">Confirm Purchase</button>
    </div>
  );
};

export default CheckoutModal;
