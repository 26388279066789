// src/components/About.js

import React, { useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import '../styles/About.css';
import '../App.css';
//import firebase from 'firebase/app';
/*
import SocialMediaMarketing from './SocialMediaMarketing';
import ContentProduction from './ContentProduction';
import Campaigns from'./Campaigns';
import EmailMarketing from'./emailMarketing';
import PaidAds from './PaidAds';
import Partnerships from './Partnerships';
import PublicRelations from './PublicRelations';
import SEO from './SEO';
import WebDesign from './webdesign';

*/


// Import your image files
import logo2 from '../assets/logos/O.Logo2.png';
import logo3 from '../assets/logos/O.Logo3.png';
import logo4 from '../assets/logos/O.Logo4.png';
import logo5 from '../assets/logos/O.Logo5.png';

// Import your team members' image files
import teamMember1 from '../assets/team/Mason Bennett.jpg';
import teamMember2 from '../assets/logos/O.Logo3.png';
import teamMember3 from '../assets/team/Pedro Schmidt.jpg';


const About = () => {
  // Define an array of services with their details
  const services = [
    {
      name: 'Social Media Marketing',
      description: 'Elevate your online presence with our dynamic social media marketing strategies. ',
      imageUrl: logo2,
      link: '/social-media-marketing',
    },
    {
      name: 'Content Production',
      description:'Ignite your brands story through captivating content production.',
      imageUrl: logo3,
      link: '/content-production',
    },
    {
      name: 'Search Engine optimization (SEO)',
      description: 'Unlock the full potential of your online presence with our cutting-edge SEO solutions. ',
      imageUrl: logo4,
      link: '/SEO',
    },
    {
      name: 'Online Public Relations ',
      description: 'Shape your brand narrative and build trust with our strategic online public relations services. ',
      imageUrl: logo5,
      link: '/public-relations',
    },
    {
      name: 'Campaigns ',
      description:'Fuel your business growth with targeted and impactful campaigns.',
      imageUrl: logo2,
      link: '/campaigns',
    },
    {
      name: ' Paid Ads',
      description: 'Maximize your ROI with our tailored paid advertising solutions.',
      imageUrl: logo3,
      link: '/paid-ads',
    },
    {
      name: 'Partnerships & Influencers ',
      description:  'Amplify your brands reach and credibility through strategic partnerships and influencer collaborations.',
      imageUrl: logo4,
      link: '/partnerships',
    },
    {
      name: 'Website Design',
      description: 'Transform your online presence with our bespoke website design services.',
      imageUrl: logo5,
      link: '/web-design',
    },
    {
      name: 'Email Marketing  ',
      description: 'Harness the power of personalized communication with our targeted email marketing campaigns.',
      imageUrl: logo2,
      link: '/email-marketing ',
    },
  ];
  // Define an array of team members with their details

  const teamMembers = [
    {
      name: 'Mason Bennet',
      position: 'co Founder & Team lead',
      imageUrl: teamMember1,
      link: '/mason-bennett-profile',
    },
    {
      name: 'Rachel Heppner ',
      position: 'co Founder & Creative Director',
      imageUrl: teamMember2,
      link: '/rachel-heppner-profile',
    },
    {
      name: 'Pedro Schmidt',
      position: 'Developer',
      imageUrl: teamMember3,
      link: '/pedro-schmidt-profile',
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/about');
        console.log(response.data); // Log the response from the backend
        // Add your desired logic here to handle the response data
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error, such as displaying an error message to the user
      }
    };

    fetchData(); // Call the fetchData function when the component mounts
  }, []); // Pass an empty dependency array to ensure the effect runs only once

    // Add the class to the body element
    useEffect(() => {
      document.body.classList.add('about-page');
      return () => {
        document.body.classList.remove('about-page');
      };
    }, []);
    
  return (
    <main >
      <div id="CENTERABOUT" className="heroABOUT">
        <div className="content-containerABOUT">
          <h1>About Us</h1>
          <p>
            Out of Office Marketing was born out of a necessity to innovate. Our
            team thrives on creativity, pushing boundaries and exploring new
            horizons while working with like-minded individuals and brands
          </p>
        </div>
      </div>

      {/*services*/}
      <div>
        <div id="CENTERSERVICE" className="heroSERVICE">
        <h1>Our Services</h1>
          <div className="services-containerSERVICE">
            <ul className="services-list">
              {services.map((service, index) => (
                <li key={index} className="service-item">
                  <img src={service.imageUrl} alt={service.name} />
                  <h2>{service.name}</h2>
                  <p>{service.description}</p>
                  <Link to={service.link}>Learn more</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      {/* Team Members */}
      <div>
        <div id="CENTERTEAM" className="heroTEAM">
        <h1>Meet Our Team</h1>
          <div className="team-containerTEAM">
            <ul className="team-list">
              {teamMembers.map((member, index) => (
                <li key={index} className="team-member">
                  <img src={member.imageUrl} alt={member.name} />
                  <h2>{member.name}</h2>
                  <p>{member.position}</p>
                  <Link to={member.link}>View Profile</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>


    </main>
  );
};

export default About;
