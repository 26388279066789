import React from 'react';
import logo2 from '../assets/logos/O.Logo3.png';
import '../styles/Service.css'; // Import CSS file for styling
import { Link } from 'react-router-dom';
const ContentProduction= () => {
    return (
        <><div className="service">
            <img src={logo2} alt="ContentProduction" />
            <h2>Content Production</h2>
            <p>Ignite your brand's story through captivating content production.</p> 
            <p> From compelling articles to stunning visuals, we create immersive experiences that resonate with your audience and inspire action.</p>
            {/* Additional content or links */}
        </div><Link to="/about" className="goBackButton">Back</Link></>
    );
};

export default ContentProduction;
