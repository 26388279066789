/* eslint-disable no-unused-vars */

// frontend/src/components/Contact.js
import React, { useState,useEffect } from 'react';

import axios from 'axios';
import '../styles/Contact.css';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [submitted, setSubmitted] = useState(false); // State to track form submission


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/contact', formData);
  
      if (response.status === 200) {
        // Handle successful response
        console.log('Message sent successfully');
        alert('Message sent successfully!');
        setSubmitted(true); // Set submitted state to true after successful form submission
        setFormData({ name: '', email: '', message: '' }); // Clear form data
      } else {
        // Handle error response
        console.error('Failed to send message');
        alert('Failed to send message. Please try again later.');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send message. Please try again later.');
    }
  };
  

///
const validateForm = () => {
  let valid = true;
  const errors = {};

  if (!formData.name.trim()) {
    errors.name = 'Name is required';
    valid = false;
  }

  if (!formData.email.trim()) {
    errors.email = 'Email is required';
    valid = false;
  } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
    errors.email = 'Invalid email address';
    valid = false;
  }

  if (!formData.message.trim()) {
    errors.message = 'Message is required';
    valid = false;
  }

  setFormErrors(errors);
  return valid;
};

const submitForm = async (formData) => {
  // Simulating form submission, replace this with actual submission logic
  setTimeout(() => {
    setSubmitted(true); // Set submitted state after a delay (simulating server response)
    setFormData({ name: '', email: '', message: '' }); // Clear form data
  }, 3000);
};

const handleSendAnotherMessage = () => {
  // Reset form data and set submitted state to false to allow sending another message
  setFormData({ name: '', email: '', message: '' });
  setSubmitted(false);
};


useEffect(() => {
  document.body.classList.add('contact-page');
  return () => {
    document.body.classList.remove('contact-page');
  };
}, []);

  return (
    <>
    <div className="CENTERCONTACT">
      <h2>Contact Us</h2>
      <form onSubmit={handleSubmit}id="content-containerCONTACT">
      <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} />
            {formErrors.name && <div className="error-message">{formErrors.name}</div>}
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />

          {formErrors.email && <div className="error-message">{formErrors.email}</div>}
          </div>
          <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea id="message" name="message" value={formData.message} onChange={handleChange}/>

          {formErrors.message && <div className="error-message">{formErrors.message}</div>}
        </div>
        <button type="submit">Send Message</button>
      </form>
    </div>
{/* Notification message */}
{submitted && (
  <div className="notification">
    Your message was sent!
    <button className="send-another-button" onClick={handleSendAnotherMessage}>
      Send Another Message
    </button>
  </div>
)}
</>

  );
}

export default Contact;
