import React from 'react';
import PropTypes from 'prop-types';
import '../styles/ShoppingCartItem.css'; // Import CSS file for ShoppingCartItem component
// Import your team members' image files
import item1 from '../assets/product-images/hat.png';
import item2 from '../assets/product-images/shirt.png';
import item3 from '../assets/product-images/long-sleeve.png';

const ShoppingCartItem = ({ item, onRemoveFromCart }) => {
    const handleRemoveFromCart = () => {
        onRemoveFromCart(item.id);
    };

// Function to dynamically select the correct image based on item's id
const selectItemImage = (itemId) => {
  switch (itemId) {
      case 1:
          return item1;
      case 2:
          return item2;
      case 3:
          return item3;
      default:
          return ''; // Return empty string if no matching image found
  }
};
    return (
        <li className="shopping-cart-item" style={{ backgroundColor: '#cd5b12' }}>
            <div className="item-details">
                          
            <div className="item-image">
                    <img src={selectItemImage(item.id)} alt={item.name} />
                </div>
                <div>
                    <span className="item-name">{item.name}</span>
                    <span className="item-price">${item.price}</span>
                </div>
            </div>
            <button style={{ backgroundColor: '#0d3149' }} className="remove-button" onClick={handleRemoveFromCart}>
                Remove
            </button>
        </li>
    );
};

ShoppingCartItem.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        image: PropTypes.string.isRequired, // Add image prop
    }).isRequired,
    onRemoveFromCart: PropTypes.func.isRequired,
};

export default ShoppingCartItem;
