import React from 'react';
import '../styles/Footer.css';
//import instagramIcon from '../assets/instagramlogo.png';
import { useLocation } from 'react-router-dom';
//import firebase from 'firebase/app';

const Footer = () => {
  // React Router hooks for navigation
  const location = useLocation();

  // Check if the current route is the home page
  const isHomePage = location.pathname === '/home';
  const isAboutPage = location.pathname === '/about';

  if (isHomePage) {
    return null; // Return null to hide the entire footer on the home page
  }

  return (
    <footer>
      <div id="FOOT">
        <div className="Footer-content">
          {/* Social Media Icons */}
          {isAboutPage && <div className="social-icons"></div>}
          <div className="developer">
            <p>
              <a
                href="https://www.instagram.com/codewithdro/?hl=es"
                style={{ textDecoration: 'none', color: 'white' }}
              >
                Made BY PEDRO SCHMIDT{' '}
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
