// TeamMember3.js

import React from 'react';
import teamMember2 from '../assets/logos/O.Logo3.png'; // Import image for Team Member 3
import additionalImage1 from '../assets/logos/O.Logo4.png';
import additionalImage2 from '../assets/logos/O.Logo5.png';


import { Link } from 'react-router-dom';

import instagram from'../assets/social-logos/instagramlogo.png'; 
import twitter from'../assets/social-logos/twitterlogo.png'; 

import '../styles/Profile-Rach.css'; // Import CSS file

const RachelHeppner = () => {
    return (
        <div className="rachel-heppner-page">
            <div className="rachel-heppner-image">
                <img src={teamMember2} alt="rachel" />
            </div>
            <div className="rachel-heppner-description">
                <h1> Rachel Heppner </h1>
                <p> co Founder & Creative Director </p>
                <div className="additional-images">
                    {/* Additional images */}
                    <img src={additionalImage1} alt="Logo 1" />
                    <img src={additionalImage2} alt="Logo 2" />
                </div>
            </div>
            <footer className="footer">
                <div className="social-media">
                    <a href="https://twitter.com/masonbennett97?lang=es" target="_blank" rel="noopener noreferrer">
                        <img src={twitter} alt="Twitter" />
                    </a>
                    <a href="https://www.instagram.com/masonbennett55/" target="_blank" rel="noopener noreferrer">
                        <img src={instagram} alt="Instagram" />
                    </a>
                </div>
                <Link to="/about" className="goBackButton">Back</Link>
            </footer>
        </div>
    );
};

export default RachelHeppner;
