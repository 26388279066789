import React from 'react';

import logo3 from '../assets/logos/O.Logo3.png';
import { Link } from 'react-router-dom';
import '../styles/Service.css'; // Import CSS file for styling

const PaidAds = () => {
    return (
        <><div className="service">
            <img src={logo3} alt="Social Media Marketing" />
            <h2>Paid Ads</h2>
            <p>Maximize your ROI with our tailored paid advertising solutions. </p>
            <p> We leverage data-driven insights to optimize your ad spend, target the right audience, and deliver compelling ads across various channels for maximum impact. </p>
            {/* Additional content or links */}
        </div><Link to="/about" className="goBackButton">Back</Link></>
    );
};

export default PaidAds;
