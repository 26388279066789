import React from 'react';
import logo2 from '../assets/logos/O.Logo2.png';
import { Link } from 'react-router-dom';
import '../styles/Service.css'; // Import CSS file for styling

const Campaigns = () => {
    return (
        <><div className="service">
            <img src={logo2} alt="Campaigns" />
            <h2>Campaigns</h2>
            <p>Fuel your business growth with targeted and impactful campaigns. </p>
            <p> From product launches to seasonal promotions, we design and execute result-driven campaigns that resonate with your audience and drive conversions.</p>
            {/* Additional content or links */}
        </div><Link to="/about" className="goBackButton">Back</Link></>
    );
};

export default Campaigns;
