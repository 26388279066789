import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../styles/ShoppingItem.css'; // Import CSS styles for the ShoppingItem component

// Import your team members' image files
import item1 from '../assets/product-images/hat.png';
import item2 from '../assets/product-images/shirt.png';
import item3 from '../assets/product-images/long-sleeve.png';

const ShoppingItem = ({ item, onAddToCart, onSaveForLater }) => {
    const [quantity, setQuantity] = useState(1); // State for quantity of items to add to cart

    const handleChangeQuantity = (e) => {
        const newQuantity = parseInt(e.target.value);
        setQuantity(newQuantity);
    };

    // Function to dynamically select the correct image based on item's id
    const selectItemImage = (itemId) => {
        switch (itemId) {
            case 1:
                return item1;
            case 2:
                return item2;
            case 3:
                return item3;
            default:
                return ''; // Return empty string if no matching image found
        }
    };

    return (
        <div className="shopping-item" style={{ backgroundColor: '#cd5b12' }}>
            <h3 className="item-name">{item.name}</h3>
            <div className="item-content">
                <div className="item-image">
                    <img src={selectItemImage(item.id)} alt={item.name} />
                </div>
                <div className="item-description">
                    <p>{item.description}</p>
                    <p>${item.price}</p>
                </div>
            </div>
            <div className="item-actions">
                <input
                    type="number"
                    value={quantity}
                    onChange={handleChangeQuantity}
                    min={1}
                    max={10} // Limit the quantity to 10 items per click
                />
                <button style={{ backgroundColor: '#0d3149' }} onClick={() => onAddToCart(item, quantity)}>Add to Cart</button>
                <button style={{ backgroundColor: '#0d3149' }} onClick={() => onSaveForLater(item)}>Save for Later</button>
            </div>
        </div>
    );
};

// Define prop types for the ShoppingItem component
ShoppingItem.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        image: PropTypes.string.isRequired,
    }).isRequired,
    onAddToCart: PropTypes.func.isRequired, // Make sure this is defined correctly
    onSaveForLater: PropTypes.func.isRequired,
};

export default ShoppingItem;
