// App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Projects from './pages/Projects';
import Shop from './pages/Shop';
import Checkout from './pages/Checkout'; // Import the Checkout component
import Footer from './components/Footer';
import TeamMember1 from './pages/Profile-Mas'; // Import TeamMember1 component
import TeamMember2 from './pages/Profile-Rach'; // Import TeamMember2 component
import TeamMember3 from './pages/Profile-Dro'; // Import TeamMember3 component
import SocialMediaMarketing from './pages/SocialMediaMarketing'; // Import SocialMediaMarketing component
import ContentProduction from './pages/ContentProduction'; // Import ContentProduction component
import Campaigns from'./pages/Campaigns';
import EmailMarketing from'./pages/emailMarketing';
import PaidAds from './pages/PaidAds';
import Partnerships from './pages/Partnerships';
import PublicRelations from './pages/PublicRelations';
import SEO from './pages/SEO';
import WebDesign from './pages/webdesign';

import './App.css'; // Import global styles

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/checkout" element={<Checkout />} /> {/* Add route for Checkout component */}
          <Route path="/mason-bennett-profile" element={<TeamMember1 />} /> {/* Route for TeamMember1 */}
          <Route path="/rachel-heppner-profile" element={<TeamMember2 />} /> {/* Route for TeamMember2 */}
          <Route path="/pedro-schmidt-profile" element={<TeamMember3 />} /> {/* Route for TeamMember3 */}
         {/* Add routes for services */}
         <Route path="/social-media-marketing" element={<SocialMediaMarketing />} />
        <Route path="/content-production" element={<ContentProduction />} />
          {/* Add routes for other services */}
          <Route path="/campaigns" element={<Campaigns />} /> {/* Add route for Campaigns */}
          <Route path="/email-marketing" element={<EmailMarketing />} /> {/* Add route for EmailMarketing */}
          <Route path="/paid-ads" element={<PaidAds />} /> {/* Add route for PaidAds */}
          <Route path="/partnerships" element={<Partnerships />} /> {/* Add route for Partnerships */}
          <Route path="/public-relations" element={<PublicRelations />} /> {/* Add route for PublicRelations */}
          <Route path="/seo" element={<SEO />} /> {/* Add route for SEO */}
          <Route path="/web-design" element={<WebDesign />} /> {/* Add route for WebDesign */}
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
