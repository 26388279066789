// TeamMember1.js

import React from 'react';
import teamMember3 from '../assets/team/Pedro Schmidt.jpg';
import additionalImage1 from '../assets/team/PedroSchmidt2.jpg';
import additionalImage2 from '../assets/team/PedroSchmidt3.jpg';

import { Link } from 'react-router-dom';

import instagram from'../assets/social-logos/instagramlogo.png'; 
import twitter from'../assets/social-logos/twitterlogo.png'; 


import '../styles/Profile-Dro.css'; // Import CSS file

const Dro = () => {
    return (
        <div className="pedro-schmidt-page">
            <div className="pedro-schmidt-image">
                <img src={teamMember3} alt="Pedro Schmidt" />
            </div>
            <div className="pedro-schmidt-description">
                <h1> Pedro Schmidt </h1>
                <p> Developer</p>
                <p> Schmidt played college football for the North Dakota Fighting Hawks from 2017 to 2021 and ran track & field in 2017-2018. </p>
                <p> Schmidt finished his final college  american football season for the Rhode Island Rams in 2022.</p>
                <p> Schmidt trained and prepared for his NFL Pro-day to play professional american football and had workouts for several leagues.</p>
                <p> Currently Schmidt is a Software Engineer/Full-Stack Developer, Visual Creator</p>
                <div className="additional-images">
                    {/* Additional images */}
                    <img src={additionalImage1} alt="1" />
                    <img src={additionalImage2} alt=" 2" />
                </div>
            </div>
            <footer className="footer">
                <div className="social-media">
                    <a href="https://twitter.com/masonbennett97?lang=es" target="_blank" rel="noopener noreferrer">
                        <img src={twitter} alt="Twitter" />
                    </a>
                    <a href="https://www.instagram.com/masonbennett55/" target="_blank" rel="noopener noreferrer">
                        <img src={instagram} alt="Instagram" />
                    </a>
                </div>
                <Link to="/about" className="goBackButton">Back</Link>
                
            </footer>
        </div>
    );
};

export default Dro;
