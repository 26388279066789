import React, { useEffect } from 'react';
import '../styles/Home.css';
import logoImage from '../assets/logos/O.Logo2.png';
import axios from 'axios';



 
<img className="hero-image" src={logoImage} alt="logo" />;

const Home = () => {
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make a POST request to your backend API with the data
        const response = await axios.get('/home', { /* Your data object here */ });
        console.log(response.data); // Log the response from the backend
        // Add your desired logic here to handle the response data
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error, such as displaying an error message to the user
      }
    };

    fetchData(); // Call the fetchData function when the component mounts
  }, []); // Pass an empty dependency array to ensure the effect runs only once

  useEffect(() => {
    console.log('Adding home-page class to body');
    document.body.classList.add('home-page');
    return () => {
      console.log('Removing home-page class from body');
      document.body.classList.remove('home-page');
    };
  }, []);
  

  return (
    <main>
      <div className="hero">
        {/* Hero Section */}
        <div className="content-container">
          <h1> OUT OF OFFICE MARKETING </h1>
        </div>
      </div>
      {/* END CENTER ID */}
      {/* END MAIN */}
    </main>
  );
};

export default Home;
