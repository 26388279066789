// TeamMember2.js

import React from 'react';
import teamMember1 from '../assets/team/Mason Bennett.jpg';
import additionalImage1 from '../assets/team/MasonBennett2.jpg';
import additionalImage2 from '../assets/team/Mason_Bennett3.jpg';


import { Link } from 'react-router-dom';

import instagram from'../assets/social-logos/instagramlogo.png'; 
import twitter from'../assets/social-logos/twitterlogo.png'; 

import '../styles/Profile-Mas.css'; // Import CSS file

const MasonBennett = () => {
    return (
        <div className="mason-bennett-page">
            <div className="mason-bennett-image">
                <img src={teamMember1} alt=" mason" />
            </div>
            <div className="mason-description">
                <h1> Mason Bennett </h1>
                <p> co Founder & Team lead </p>
                <p>Bennett played college football for the North Dakota Fighting Hawks from 2016 to 2019 </p>
                <p>Bennett was drafted in the first round, eighth overall by the Hamilton Tiger-Cats in the 2020 CFL Draft, but did not play in 2020 due to the cancellation of the 2020 CFL season.</p>
                <p> He then signed with the team on January 21, 2021.</p>
                <div className="additional-images">
                    {/* Additional images */}
                    <img src={additionalImage1} alt="Logo 1" />
                    <img src={additionalImage2} alt="Logo 2" />
                </div>
            </div>
            <footer className="footer">
                <div className="social-media">
                    <a href="https://twitter.com/masonbennett97?lang=es" target="_blank" rel="noopener noreferrer">
                        <img src={twitter} alt="Twitter" />
                    </a>
                    <a href="https://www.instagram.com/masonbennett55/" target="_blank" rel="noopener noreferrer">
                        <img src={instagram} alt="Instagram" />
                    </a>
                </div>
                <Link to="/about" className="goBackButton">Back</Link>
                
            </footer>

        </div>
    );
};

export default MasonBennett;
