/* eslint-disable no-unused-vars */

import React, { useEffect, useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';


import '../styles/Header.css';
//import firebase from 'firebase/app';


const Header = () => {
  // React Router hooks for navigation
  const location = useLocation();
  const navigate = useNavigate();

  // Function to navigate to the Home page
  const navigateToHome = () => {
    navigate('/home');
  };

  // Check if the screen size is small
  const isSmallScreen = useCallback(() => {
    return window.innerWidth <= 600; // iPhone 13 Pro width
  }, []);
    // State to manage the small screen status
    const [smallScreen, setSmallScreen] = useState(isSmallScreen());
    
 // Function to handle window resize event
 const handleResize = () => {
  setSmallScreen(isSmallScreen());
};

// Add state to manage the visibility of the dropdown
const [dropdownVisible, setDropdownVisible] = React.useState(false);

// Function to close the dropdown menu
const closeDropdown = () => {
  setDropdownVisible(false);
};

// Function to handle dropdown menu item click
const handleDropdownItemClick = (item) => {
  console.log(`Navigating to: /${item}`);
  navigate(`/${item}`);
  closeDropdown();
};

// Function to toggle the dropdown visibility
const toggleDropdown = () => {
  setDropdownVisible((prevState) => !prevState);
};

useEffect(() => {
  const handleResize = () => {
    // Update the state based on the current window width
    setSmallScreen(window.innerWidth <= 600);
  };

  // Add event listener for window resize
  window.addEventListener('resize', handleResize);

  // Cleanup function to remove event listener for window resize
  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []); // Ensure proper dependencies are provided if needed

// Cleanup function to remove event listener for menu button
useEffect(() => {
  const menuButton = document.getElementById('bar-menu-container');

  if (menuButton) {
    menuButton.addEventListener('click', toggleDropdown);
  }

  // Cleanup function to remove event listener for menu button
  return () => {
    if (menuButton) {
      menuButton.removeEventListener('click', toggleDropdown);
    }
  };
}, []); // Ensure proper dependencies are provided if needed

// Render the dropdown menu items dynamically
const dropdownItems = ['home', 'about', 'projects', 'shop', 'contact'];

//scrolll 
const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
const [visible, setVisible] = useState(true);

useEffect(() => {
  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 50);
    setPrevScrollPos(currentScrollPos);
  };

  const debouncedHandleScroll = debounce(handleScroll, 75); // Adjust debounce time as needed

  window.addEventListener('scroll', debouncedHandleScroll);

  return () => {
    window.removeEventListener('scroll', debouncedHandleScroll);
  };
}, [prevScrollPos]);

// Add handleScroll to the dependency array


  return (
    <header style={{ opacity: visible ? 1 : 0, transition: 'opacity 0.3s ease' }}>
      <div id="Top">
        {/* Navigation menu icon */}
        {isSmallScreen() && (
          // Render hamburger menu for small screens
          <div id="bar-menu-container">
            &#9776;
            {/* Render dropdown menu items dynamically */}
            <div className={`dropdown-menu ${dropdownVisible ? 'visible' : ''}`}>
              {dropdownItems.map((item, index) => (
                <a
                  key={index}
                  href={`/${item}`} // Keep it as is for '/about'
                  onClick={() => handleDropdownItemClick(item)}
                >
                  {item.charAt(0).toUpperCase() + item.slice(1)} {/* Capitalize the first letter */}
                </a>
              ))}
            </div>
            {/* Dark overlay */}
            <div
              id="dark-overlay"
              style={{ display: dropdownVisible ? 'block' : 'none' }}
              onClick={closeDropdown}
            ></div>
          </div>
        )}
        {/* Render navigation bar for larger screens */}
        {!isSmallScreen() && (
          <div className={`sticky-menu-main ${visible ? 'visible' : 'hidden'}`}>
            {location.pathname === '/home' && (
              <div className="nav-grid">
                <div className="nav-message">
                  <ul>
                    <li>
                      Digital Marketing and more, we’re here for collaborative,
                      meaningful partnerships dedicated to propelling brands and
                      individuals to unprecedented heights.
                    </li>
                    <li>Let's redefine digital success together.</li>
                    <li></li>
                  </ul>
                </div>
              </div>
            )}

            <div className="navigation-bar">
              <div className="menu-main">
                <div className="main-menu-container">
                  <div className="main-nav-text">

                    {/* About page */}
                    <a href="/about"  > ABOUT  </a>

                    {/* Projects page */}
                    <a href="/projects"  > PROJECTS  </a>

                    {/* Shop page */}
                    <a href="/shop"  > SHOP  </a>

                    <a href="/contact"  > CONTACT  </a>
                    {/* Contact page */}
                    <div className="sub-nav-text">

                      <ul>
                        <a
                          href="mailto:hello@outofoffice.ca"

                        >
                          Email
                        </a>
                      </ul>

                      <ul>
                        <a
                          href="https://www.instagram.com/outofoffice.ca?igsh=ZDE1MWVjZGVmZQ=="
                          target="_blank"
                          rel="noopener noreferrer"

                        >
                          Instagram
                        </a>
                      </ul>
                    </div>
                  </div>

                  {/* Additional navigation bar items for larger screens */}
                  {window.innerWidth > 450 && location.pathname !== '/home' && (
                    <div className="nav-menu-icon-left" onClick={navigateToHome}></div>
                  )}
                  {/* Additional navigation bar items for larger screens */}
                  {window.innerWidth > 450 && (
                    <div id="nav-menu-icon-right" onClick={navigateToHome}></div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
