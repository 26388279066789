import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import SavedForLaterItem from '../components/SavedForLaterItem'; // Import SavedForLaterItem component
import ShoppingCartItem from '../components/ShoppingCartItem'; // Import ShoppingCartItem component
import axios from 'axios';
import '../styles/Checkout.css';


import item1 from '../assets/product-images/hat.png';
import item2 from '../assets/product-images/shirt.png';
import item3 from '../assets/product-images/long-sleeve.png';


//firebase 
//import firebase from 'firebase/app';
//import 'firebase/functions';


const Checkout = () => {


    // Function to dynamically select the correct image based on item's id
    const selectItemImage = (itemId) => {
        switch (itemId) {
            case 1:
                return item1;
            case 2:
                return item2;
            case 3:
                return item3;
            default:
                return ''; // Return empty string if no matching image found
        }
    };

    const location = useLocation();
    const cartItems = location.state.cartItems;

    const [itemQuantities, setItemQuantities] = useState(
        cartItems.reduce((acc, item) => {
            acc[item.id] = 1;
            return acc;
        }, {})
    );

    const [savedItems, setSavedItems] = useState([]);

    const handleQuantityChange = (itemId, newQuantity) => {
        setItemQuantities({
            ...itemQuantities,
            [itemId]: newQuantity,
        });
    };

    const removeItemFromCheckout = (itemId) => {
        const updatedQuantities = { ...itemQuantities };
        delete updatedQuantities[itemId];
        setItemQuantities(updatedQuantities);
    };

    const calculateTotalPrice = () => {
        let totalPrice = 0;
        cartItems.forEach(item => {
            if (itemQuantities[item.id]) {
                totalPrice += item.price * itemQuantities[item.id];
            }
        });
        return totalPrice;
    };



   /* const handlePayNow = async () => {
        try {
            alert('Redirecting to secure checkout...');
            const processPayment = firebase.functions().httpsCallable('processPayment');
            const totalPrice = calculateTotalPrice(); // Calculate the total price
            const result = await processPayment({ amount: totalPrice });
            // Handle success
            console.log(result.data); // Log the success message
            alert('Payment processed successfully');
        } catch (error) {
            // Handle error
            console.error('Error processing payment:', error); // Log the error message
            alert('Error processing payment. Please try again later.');
        }
    };
    
*/
    const moveToCart = (savedItem) => {
        setSavedItems(savedItems.filter(item => item.id !== savedItem.id));
        setItemQuantities({
            ...itemQuantities,
            [savedItem.id]: 1,
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post('/checkout');
                console.log(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);


    useEffect(() => {
        document.body.classList.add('checkout-page');
        return () => {
          document.body.classList.remove('checkout-page');
        };
      }, []);
      
    return (
        <div className="checkout-container checkout-page">
            <div className="cart-section">
                <h2>Cart Items</h2>
                <ul>
                    {cartItems.map((item) => (
                        <li key={item.id}>
                            <ShoppingCartItem item={item} onRemoveFromCart={removeItemFromCheckout} />
                        </li>
                    ))}
                </ul>
                <Link to="/shop" className="BackButton">Shop</Link>
            </div>
            <div className="saved-for-later-section">
                <h2>Saved For Later</h2>
                {savedItems.map((savedItem) => (
                    <SavedForLaterItem key={savedItem.id} item={savedItem} onAddBackToCart={moveToCart} onRemoveFromSaved={moveToCart} />
                ))}
            </div>
            <div className="checkout-section">
                <h1>Checkout</h1>
                <ul className="checkout-item-list">
                    {cartItems.map((item) => (
                        <li className="checkout-item" key={item.id}>
                            <div className="item-image">
                                <img src={selectItemImage(item.id)} alt={item.name} />
                            </div>
                            <div className="item-details">
                                <span className="checkout-item-name">{item.name}</span> -
                                <span className="checkout-item-price">${item.price}</span>
                            </div>
                            <div className="quantity">
                                <span>Quantity:</span>
                                <input
                                    type="number"
                                    value={itemQuantities[item.id] || ''}
                                    onChange={(e) => handleQuantityChange(item.id, parseInt(e.target.value))}
                                    min="1"
                                />
                            </div>
                        </li>
                    ))}
                </ul>
                <div className="total">
                    Total Price: ${calculateTotalPrice()}
                </div>
                {/*<button style={{ backgroundColor: '#0d3149' }} onClick={handlePayNow}>Pay Now</button> */}
            </div>
        </div>
    );
};

export default Checkout;
