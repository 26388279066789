/* eslint-disable jsx-a11y/img-redundant-alt */
// src/components/Projects.js
import React, { useEffect } from 'react';
import axios from 'axios';
import '../styles/Projects.css';



const Projects = () => {

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/projects');
        console.log(response.data); // Log the response from the backend
        // Add your desired logic here to handle the response data
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error, such as displaying an error message to the user
      }
    };

    fetchData(); // Call the fetchData function when the component mounts
  }, []); // Pass an empty dependency array to ensure the effect runs only once

  useEffect(() => {
    document.body.classList.add('projects-page');
    return () => {
      document.body.classList.remove('projects-page');
    };
  }, []);
  
  return (
    <main>
      <div id="CENTERPROJECT" className="heroPROJECT">
        <div className="content-containerPROJECT">
          <h1>Our Projects</h1>
          <p>
            Explore our exciting projects and see the creative work we've done
            for our clients.
          </p>
          {/* Project #1 */}
          <div className="square-container">
            {/* Image inside the square container */}

            <img src={require('../assets/logos/O.Logo2.png')} alt="Project Image" />

            {/* Transparent text overlay */}
            <div className="text-overlay">
              <h2>Project #1 </h2>
              <p>The founder at Out of Office Mason Bennett worked with.... </p>
            </div>
          </div>

          {/* Project #2 */}
          <div className="square-container">
            {/* Image inside the square container */}
            <img src={require('../assets/logos/O.Logo3.png')} alt="Project Image" />

            {/* Transparent text overlay */}
            <div className="text-overlay">
              <h2>Project #2</h2>
              <p>Description for Project #2...</p>
            </div>
          </div>

          {/* Project #3 */}
          <div className="square-container">
            {/* Image inside the square container */}
            <img src={require('../assets/logos/O.Logo3.png')} alt="Project Image" />

            {/* Transparent text overlay */}
            <div className="text-overlay">
              <h2>Project #3</h2>
              <p>Description for Project #3...</p>
            </div>
          </div>

          {/* Project #4 */}
          <div className="square-container">
            {/* Image inside the square container */}
            <img src={require('../assets/logos/O.Logo4.png')} alt="Project Image" />

            {/* Transparent text overlay */}
            <div className="text-overlay">
              <h2>Project #4</h2>
              <p>Description for Project #4...</p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Projects;
