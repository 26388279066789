import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Shop.css';
import ShoppingItem from '../components/ShoppingItem';
import ShoppingCartItem from '../components/ShoppingCartItem'; // Import the ShoppingCartItem component
import SavedForLaterItem from '../components/SavedForLaterItem'; // Import the SavedForLaterItem component
import CheckoutModal from '../components/CheckoutModal'; // Import the CheckoutModal component
import axios from 'axios';


// Dummy data for demonstration purposes
const items = [
  {
    id: 1,
    name: 'Hat',
    image: 'hat.png',
    price: 20,
    description: 'A stylish hat for any occasion.'
  },
  {
    id: 2,
    name: 'Shirt',
    description: 'Comfortable and trendy shirt.',
    price: 30,
    image: 'shirt.png',
  },
  {
    id: 3,
    name: 'Long-Sleeve',
    description: 'Long-sleeve shirt for a cool look.',
    price: 40,
    image: 'long-sleeve.png',
  },
  // Add more items as needed
];

const Shop = () => {
  const navigate = useNavigate();
  const [cart, setCart] = useState([]);
  const [savedForLater, setSavedForLater] = useState([]);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false); // State for showing/hiding checkout modal
  const [showCartDropdown, setShowCartDropdown] = useState(false); // State for showing/hiding shopping cart dropdown
  const [showSavedForLaterDropdown, setShowSavedForLaterDropdown] = useState(false); // State for showing/hiding saved for later dropdown

  const addItemToCart = (item, quantity) => {
    const newItem = { ...item, quantity: quantity || 1 };
    setCart([...cart, newItem]);
    // Display notification
    alert(`${item.name} added to cart.`);
  };

  const removeItemFromCart = (id) => {
    const newCart = cart.filter(item => item.id !== id);
    setCart(newCart);
  };

  const saveForLater = (item) => {
    setSavedForLater([...savedForLater, item]);
  };

  const addBackToCart = (item) => {
    setCart([...cart, item]);
    setSavedForLater(savedForLater.filter(savedItem => savedItem.id !== item.id));
  };

  const checkout = () => {
    setShowCheckoutModal(true);
    navigate('/checkout', { state: { cartItems: cart } });
  };

  const toggleCartDropdown = () => {
    setShowCartDropdown(!showCartDropdown);
  };

  const toggleSavedForLaterDropdown = () => {
    setShowSavedForLaterDropdown(!showSavedForLaterDropdown);
  };

  const handleCloseModal = () => {
    setShowCheckoutModal(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/shop');
        console.log(response.data); // Log the response from the backend
        // Add your desired logic here to handle the response data
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error, such as displaying an error message to the user
      }
    };

    fetchData(); // Call the fetchData function when the component mounts
  }, []); // Pass an empty dependency array to ensure the effect runs only once

  useEffect(() => {
    document.body.classList.add('shop-page');
    return () => {
      document.body.classList.remove('shop-page');
    };
  }, []);


  return (
    <main>
      <div id="CENTERSHOP" className="heroSHOP">
        <div className="content-containerSHOP">
          <h1> Shop </h1>
          <p>
            Check out our shop for exclusive products and merchandise. Find
            something special for yourself or a loved one.
          </p>

          {/* Shopping Items */}
          <div className="shopping-items">
            {items.map((item) => (
              <ShoppingItem
                key={item.id}
                item={item}
                onAddToCart={addItemToCart}
                onSaveForLater={saveForLater}
              />
            ))}
          </div>

          {/* View Shopping Cart Button */}
          <button onClick={toggleCartDropdown} className="view-cart-button">
            View Shopping Cart
          </button>

          {/* View Saved For Later Button */}
          <button onClick={toggleSavedForLaterDropdown} className="view-saved-button">
            View Saved For Later
          </button>

          {/* Shopping Cart Dropdown */}
          {showCartDropdown && (
            <div className="shopping-cart">
              <h2>Shopping Cart</h2>
              <ul>
                {cart.map((cartItem) => (
                  <ShoppingCartItem
                    key={cartItem.id}
                    item={cartItem}
                    onRemoveFromCart={removeItemFromCart}
                  />
                ))}
              </ul>
              <button onClick={checkout}>Check Out</button>
            </div>
          )}

          {/* Saved for Later Dropdown */}
          {showSavedForLaterDropdown && (
          <div className="saved-for-later">
            <h2>Saved for Later</h2>
            <ul>
              {savedForLater.map((savedItem) => (
                <SavedForLaterItem
                  key={savedItem.id}
                  item={savedItem}
                  onAddToCart={addBackToCart}
                  onRemoveFromSaved={removeItemFromCart}
                />
              ))}
            </ul>
          </div>
          )}
          
        </div>
      </div>
      {showCheckoutModal && <CheckoutModal cartItems={cart} onCloseModal={handleCloseModal} />}
    </main>
  );
};

export default Shop;
