import React from 'react';
import logo5 from '../assets/logos/O.Logo5.png';
import  '../styles/WebsiteDesign.css'; // Import CSS module for styling
import { Link } from 'react-router-dom';

const WebsiteDesign = () => {
    return (
        <div className="web-service">
            <img src={logo5} alt="web-service" />
            <h2>Website Design</h2>
            <p>Transform your online presence with our bespoke website design services.</p>
            <p>We blend creativity with functionality to create stunning and user-friendly websites that leave a lasting impression and drive conversions.</p>
            <h3>Basic Website</h3>
            <p>$1,000</p>
            <ul>
                <li>Basic Pages</li>
                <li>Basic non-complex Design</li>
                <li>Domain Registration & Hosting</li>
                <li>Input Content, no created Content</li>
                <li>External Links</li>
                <li>Maintenance Quarterly </li>
                <li>Front-End Development only</li>
            </ul>
            <h3>Premium Website</h3>
            <p>$3,000</p>
            <ul>
                <li>Basic Pages / UI UX Designed Pages </li>
                <li>Functionality included (e-commerce, user accounts... more)</li>
                <li>Complex Design</li>
                <li>Domain Registration & Hosting</li>
                <li>Input and Created Content</li>
                <li>External Links</li>
                <li>Maintenance Quarterly</li>
                <li>Front End & Back-End Development</li>
            </ul>
            <Link to="/about" className="goBackButton">Back</Link>
        </div>
    );
};

export default WebsiteDesign;
