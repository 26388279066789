import React from 'react';
import logo2 from  '../assets/logos/O.Logo3.png';
import { Link } from 'react-router-dom';
import '../styles/Service.css'; // Import CSS file for styling

const SocialMediaMarketing = () => {
    return (
        <><div className="service">
            <img src={logo2} alt="Social Media Marketing" />
            <h2>Social Media Marketing</h2>
            <p>Elevate your online presence with our dynamic social media marketing strategies.</p>
             <p>We craft engaging and shareable content tailored to your target audience, helping you build meaningful connections and drive growth.</p>
            {/* Additional content or links */}
        </div><Link to="/about" className="goBackButton">Back</Link></>
    );
};

export default SocialMediaMarketing;
