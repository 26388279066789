import React from 'react';

import logo4 from '../assets/logos/O.Logo4.png';
import { Link } from 'react-router-dom';
import '../styles/Service.css'; // Import CSS file for styling

const PartnershipsInfluencers = () => {
    return (
        <><div className="service">
            <img src={logo4} alt="Social Media Marketing" />
            <h2>Partnerships & Influencers</h2>
            <p>Amplify your brand's reach and credibility through strategic partnerships and influencer collaborations.</p>
            <p> We identify and engage with influencers aligned with your brand values, creating authentic connections and driving engagement.</p>
            {/* Additional content or links */}
        </div><Link to="/about" className="goBackButton">Back</Link></>
    );
};

export default PartnershipsInfluencers;
