import React from 'react';
import logo2 from  '../assets/logos/O.Logo3.png';
import '../styles/Service.css'; // Import CSS file for styling
import { Link } from 'react-router-dom';
const SEO = () => {
    return (
        <><div className="service">
            <img src={logo2} alt="SEO" />
            <h2>Search Engine optimization (SEO)</h2>
            <p>Unlock the full potential of your online presence with our cutting-edge SEO solutions.</p> 
            <p> We implement proven strategies to boost your website's visibility, attract quality traffic, and dominate search engine rankings.</p>
            {/* Additional content or links */}
        </div><Link to="/about" className="goBackButton">Back</Link></>
    );
};

export default SEO;
