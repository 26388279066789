import React from 'react';

import logo3 from '../assets/logos/O.Logo3.png';
import { Link } from 'react-router-dom';
import '../styles/Service.css'; // Import CSS file for styling

const PublicRelations = () => {
    return (
        <><div className="service">
            <img src={logo3} alt="PublicRelations" />
            <h2>Public Relations</h2>
            <p>Shape your brand narrative and build trust with our strategic online public relations services.</p>
            <p>  We craft compelling stories, manage your online reputation, and engage with your audience to enhance credibility and authority. </p>
            {/* Additional content or links */}
        </div><Link to="/about" className="goBackButton">Back</Link></>
    );
};

export default PublicRelations;
