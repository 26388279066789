import React from 'react';
import { Link } from 'react-router-dom';
import logo2 from '../assets/logos/O.Logo2.png';

import '../styles/Service.css'; // Import CSS file for styling

const EmailMarketing = () => {
    return (
        <><div className="service">

            <img src={logo2} alt="Email Marketing" />
            <h2>Email Marketing</h2>
            <p>We create engaging and relevant content for your audience.</p>
            {/* Additional content or links */}
        </div><Link to="/about" className="goBackButton">Back</Link></>
    );
};

export default EmailMarketing;
